































































































import Vue from "vue";
import { dispatch, ListQuery, DrawActivity } from "@/store";
import { download, xlsx } from "@/utils";
import moment from "moment";
import { url } from "@/api";

export default Vue.extend({
  data() {
    return {
      dispatch,
      mallDrawActivity: url.mallDrawActivity,
      loading: false,
      form: this.$form.createForm(this),
      list: [],
      query: {
        ordering: "id",
        page_number: 1,
        page_size: 10
      },
      count: 0,
      visible: false,
      isAdd: false,
      model: {} as DrawActivity
    };
  },
  computed: {
    fields() {
      return [
        {
          title: "活动 ID",
          dataIndex: "code",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入活动 ID",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            },
            {
              validator: (rule: any, value: string, callback: Function) => {
                if (!/^[A-Za-z0-9_]*$/.test(value)) {
                  callback("活动 ID 只能为英文和数字组合");
                  return;
                }
                callback();
              }
            }
          ]
        },
        {
          title: "活动标题",
          dataIndex: "title",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入活动标题",
              trigger: "blur"
            },
            {
              max: 40,
              message: "请输入少于 40 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "活动描述",
          dataIndex: "desc",
          type: "textarea",
          rules: [
            {
              required: true,
              whitespace: true,
              message: "请输入活动描述",
              trigger: "blur"
            },
            {
              max: 400,
              message: "请输入少于 400 个字符",
              trigger: "blur"
            }
          ]
        },
        {
          title: "限制 MIAO COIN 数",
          dataIndex: "limit_coin",
          type: "number",
          min: 1,
          precision: 0
        },
        {
          title: "白名单用户 ID",
          dataIndex: "allow_user_ids",
          type: "userIds"
        },
        {
          title: "导入白名单",
          dataIndex: "allow_user_ids_upload",
          userIdsIndex: "allow_user_ids",
          type: "userIdsUpload"
        },
        {
          title: "是否开启",
          dataIndex: "is_use",
          type: "switch",
          customRender: (text: boolean) => (text === true ? "开启" : "关闭")
        },
        {
          title: "结束时间",
          dataIndex: "expire_time",
          type: "datetime",
          rules: [
            {
              required: true,
              message: "请选择结束时间"
            }
          ],
          scopedSlots: { customRender: "time" }
        }
      ];
    },
    columns() {
      let columns: any = this.fields.slice();
      columns.splice(4, 1);
      columns.splice(4, 1);
      columns = columns.concat([
        {
          title: "创建时间",
          dataIndex: "create_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          scopedSlots: { customRender: "time" }
        },
        {
          title: "操作",
          key: "actions",
          scopedSlots: { customRender: "actions" },
          width: "350px"
        }
      ]);

      return columns;
    }
  },
  methods: {
    getList(query: ListQuery) {
      this.loading = true;
      dispatch.drawGetActivityList(query).then(res => {
        this.loading = false;
        this.list = res.results;
        this.query = {
          ...query,
          page_number: res.page_number,
          page_size: res.page_size
        };
        this.count = res.count;
        this.loading = false;
      });
    },
    onAdd() {
      this.visible = true;
      this.isAdd = true;
      this.model = { is_use: true } as DrawActivity;
    },
    onEdit(drawActivity: DrawActivity) {
      this.visible = true;
      this.isAdd = false;
      this.model = drawActivity;
    },
    onOk() {
      this.visible = false;
      this.model = {} as DrawActivity;
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter
      });
    },
    onFilterChange() {
      const filter = this.form.getFieldsValue();
      this.getList({
        ...this.query,
        ...filter,
        page_number: 1
      });
    },
    onFilterReset() {
      this.form.resetFields();
      this.onFilterChange();
    },
    onTableChange(page: any, filters: any, sorter: any) {
      let ordering =
        sorter.order === "ascend" ? "-" + (sorter.columnKey || "") : "";
      ordering = sorter.order === "descend" ? sorter.columnKey || "" : ordering;
      this.getList({
        ...this.query,
        page_number: page.current,
        page_size: page.pageSize,
        ordering: ordering || this.query.ordering
      });
    },
    onShow(drawActivity: DrawActivity) {},
    onDelete(drawActivity: DrawActivity) {
      this.$confirm({
        title: "确认删除？",
        centered: true,
        onOk: () => {
          dispatch
            .drawPatchActivity({ ...drawActivity, is_delete: true })
            .then(() => {
              this.$message.success("删除成功");
              const filter = this.form.getFieldsValue();
              this.getList({
                ...this.query,
                ...filter
              });
            });
        }
      });
    },
    onExport(drawActivity: DrawActivity) {
      this.$message.info("抽奖信息导出中…");
      this.loading = true;
      let list: any[] = [];
      const getList = (pageNumber: number) =>
        dispatch
          .drawGetList({
            code: drawActivity.code,
            page_number: pageNumber,
            page_size: 1000
          } as any)
          .then(res => {
            list = [...list, ...res.results];
            if (res.count > list.length) {
              getList(pageNumber + 1);
            } else {
              const data = [this.columns.map((column: any) => column.title)];
              list.map(item => {
                data.push([
                  ...this.columns.map((column: any) => {
                    if (!column.dataIndex) return "";
                    const value = item[column.dataIndex];
                    if (!value) return "";
                    if (column.customRender) {
                      return column.customRender(value);
                    } else if (column.scopedSlots?.customRender === "time") {
                      return value
                        ? moment(value).format("YYYY-MM-DD HH:mm:ss")
                        : "";
                    } else {
                      return value;
                    }
                  })
                ]);
              });
              const file = xlsx([
                {
                  name: "抽奖信息列表",
                  data,
                  cols: [
                    { wch: 20 },
                    { wch: 10 },
                    { wch: 20 },
                    { wch: 15 },
                    { wch: 20 },
                    { wch: 20 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 },
                    { wch: 30 }
                  ]
                }
              ]);
              this.$message.success("抽奖信息导出成功，正在下载…");
              download(file, "抽奖列表.xlsx");
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
            this.$message.error("抽奖信息导出失败");
          });
      getList(1);
    }
  },
  mounted() {
    this.getList(this.query);
  }
});
